<template>
  <div class="contents errorpage">
    <div>
      <h3><i class="fas fa-exclamation-triangle"></i>Error 404</h3>
      <p>
        현재 찾으시는 페이지는<br />
        접근이 제한되어 있거나 없는 페이지 입니다.
      </p>
      <p>
        홈으로 이동하거나 이전 페이지로 이동 후 <br />다른 페이지를
        클릭해주세요.
      </p>
      <button class="point">
        <router-link to="/">
          홈으로 이동
        </router-link>
      </button>
    </div>
  </div>
</template>
<script>
import { setFooter } from "@/mixins/setFooter";
import { setNavbar } from "@/mixins/setNavbar";
export default {
  mixins: [setNavbar, setFooter],
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
